import React, {
	createContext,
	useState,
	ReactNode,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { useAuth } from "./auth-context";
import { start } from "repl";
import {
	RecentlyAccessedFile,
	useMsGraphRecentDocs,
} from "@/hooks/useMsGraphRecentDocs";

export interface CalendarItem {
	id: string;
	subject: string;
	start: Date;
	iCalUId?: string;
	organizer: {
		emailAddress: {
			address: string;
			name: string;
		};
	};
	responseStatus: {
		response: string;
		time: Date;
	};
	attendees?: Array<{
		type: string;
		status: {
			response: string;
			time: Date;
		};
		emailAddress: {
			address: string;
			name: string;
		};
	}>;

	onlineMeeting: {
		joinUrl: string;
	};
}

interface CalendarContextProps {
	events: CalendarItem[];
	recentFiles: RecentlyAccessedFile[];
	setResponseStatus: (
		event: CalendarItem,
		response: "accepted" | "tentative" | "declined"
	) => void;
	addCalendarItemRaw: (event: Event) => void;
}

const CalendarContext = createContext<CalendarContextProps | undefined>(
	undefined
);

interface CalendarProviderProps {
	children: ReactNode;
}

export const CalendarProvider: React.FC<CalendarProviderProps> = ({
	children,
}) => {
	const [events, setEvents] = useState<CalendarItem[]>();
	const { getMsGraphToken } = useAuth();
	const [deltaLink, setDeltaLink] = useState<string>();
	const recentFiles = useMsGraphRecentDocs();
	//const deltaLinkRef = useRef();
	//const eventsRef = useRef<Event[]>([]);

	const addCalendarItemRaw = (Raw: any) => {
		setEvents([...events, eventToCalendarItem(Raw)]);
	};

	const setResponseStatus = async (
		event: CalendarItem,
		response: "accepted" | "tentative" | "declined"
	) => {
		console.log("Setting response status", event, response);
		const responseMap = {
			accepted: "accept",
			tentative: "tentativelyAccept",
			declined: "decline",
		};

		const url = `https://graph.microsoft.com/v1.0/me/events/${event.id}/${responseMap[response]}`;
		const token = await getMsGraphToken();
		// add token as bearer token and fetch url
		const responseStatus = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				sendResponse: true,
			}),
		});

		if (!responseStatus.ok) {
			throw new Error(
				`Failed to fetch item: ${responseStatus.statusText}`
			);
		}

		// This might not be the best idea TODO: check if this is the best way to do it
		if (response === "accepted" || response === "tentative") {
			event.responseStatus = {
				response: response,
				time: new Date(),
			};
			setEvents([...events]);
		} else if (response === "declined") {
			// optimistic update in local cache
			const eventsRemoved = events?.filter((e) => e.id !== event.id);
			setEvents(eventsRemoved);
		}
	};

	const getDelta = async () => {
		if (!deltaLink) {
			console.log("No delta link set, doing nothing");
			return;
		}

		//console.log("Fetching delta link:", deltaLink);

		const token = await getMsGraphToken();
		// add token as bearer token and fetch url
		const response = await fetch(deltaLink, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		// Check if the fetch request was successful
		if (!response.ok) {
			throw new Error(`Failed to fetch item: ${response.statusText}`);
		}

		// parse response
		//console.log("We got data from delta query", response)
		try {
			const data = await response.json();
			//console.log("Delta data:", data);

			// Add new events to state
			if (data.value?.length > 0) {
				const removedEvents = data.value.filter(
					(event: any) => event["@removed"]
				);

				const newEventsRaw = data.value.filter(
					(event: any) => !event["@removed"]
				);

				const newEvents: CalendarItem[] = newEventsRaw.map(
					(event: any): CalendarItem => eventToCalendarItem(event)
				);

				const existingEventsObject: { [key: string]: CalendarItem } =
					{};
				events.forEach((event) => {
					existingEventsObject[event.id] = event;
				});

				newEvents.forEach((event) => {
					existingEventsObject[event.id] = event;
				});

				// remove all deleted events
				removedEvents.forEach((item: { id: string }) => {
					delete existingEventsObject[item.id];
				});

				setEvents(Object.values(existingEventsObject));
			}

			// Update delta link
			const deltaLink = data["@odata.deltaLink"];
			//console.log("Delta link:", deltaLink);
			setDeltaLink(deltaLink);
		} catch (e) {
			console.log("Error parsing JSON when fetching calendar delta", e);
		}
	};

	useEffect(() => {
		// Start the interval when the component mounts
		const id = setTimeout(async () => {
			await getDelta();
		}, 30000);

		// Clear the interval when the component unmounts
		return () => {
			clearInterval(id);
		};
	}, [deltaLink]); // Empty dependency array means this useEffect runs once, similar to componentDidMount

	//console.log("Events", events);
	const eventToCalendarItem = (event: any): CalendarItem => {
		return {
			id: event.id,
			subject: event.subject,
			iCalUId: event.iCalUId,
			organizer: event.organizer,
			start: new Date(event.start.dateTime),
			responseStatus: {
				response: event.responseStatus?.response,
				time: event.responseStatus?.time
					? new Date(event.responseStatus.time)
					: null,
			},
			onlineMeeting: { joinUrl: event?.onlineMeeting?.joinUrl },
			attendees: event.attendees?.map((attendee: any) => {
				return {
					type: attendee.type,
					status: {
						response: attendee.status?.response,
						time: attendee.status?.time
							? new Date(attendee.status.time)
							: null,
					},
					emailAddress: attendee.emailAddress,
				};
			}),
		};
	};

	useEffect(() => {
		const fetchMyEvents = async (startDate: Date, endDate: Date) => {
			try {
				//console.log("Calendar context: Fetching events", startDate.toISOString(), endDate.toISOString());
				const url = `https://graph.microsoft.com/v1.0/me/calendarView/delta?startDateTime=${startDate.toISOString()}&endDateTime=${endDate.toISOString()}`; //&$select=subject,start,end,organizer,iCalUId,responseStatus,attendees,onlineMeeting}`;
				const token = await getMsGraphToken();
				// add token as bearer token and fetch url
				const response = await fetch(url, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				// parse response
				const data = await response.json();
				//const eventIds = linkedEvents.map((linkedEvent) => linkedEvent.iCalUId);
				//console.log("Looking for ", eventIds)
				//console.log(data);
				//setProjectEvents(data.value.filter((event:any) => eventIds.find((id) =>  id === event.iCalUId)));
				const events = data.value.map(
					(event: any): CalendarItem => eventToCalendarItem(event)
				);

				setEvents(events);
				// get delta link
				const deltaLink = data["@odata.deltaLink"];
				setDeltaLink(deltaLink);
			} catch (e) {
				console.log("Error fetching calendar events", e);
			}
		};
		// Define calendar filters start end end date. Start date should be today -1 month and end date should be today + 3 months
		const startDate = new Date();
		const endDate = new Date();
		endDate.setMonth(startDate.getMonth() + 3);
		startDate.setMonth(startDate.getMonth() - 1);

		fetchMyEvents(startDate, endDate);
	}, []);

	return (
		<CalendarContext.Provider
			value={{
				events,
				recentFiles,
				setResponseStatus,
				addCalendarItemRaw,
			}}
		>
			{children}
		</CalendarContext.Provider>
	);
};

export const useCalendar = () => {
	const context = React.useContext(CalendarContext);
	if (!context) {
		throw new Error("useCalendar must be used within a CalendarProvider");
	}
	return context;
};
