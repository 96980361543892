import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useLocalStorageState } from './useLocalStorageState';



export const useAppVersionListener = () => {
    const [frontEndVersion, setFrontEndVersion] = useLocalStorageState('frontEndVersion', undefined);
    const [version, setVersion] = useState<string | undefined>(undefined);

    useEffect(() => {
        console.log('useAppVersionListener', version, frontEndVersion);
      
        // If version is still not fetched, do nothing
        if (!version) return;
      
        if (!frontEndVersion) {
          console.log('No frontend version was set, Setting frontEndVersion', version);
            setFrontEndVersion(version);
          return;
        }
      
        // If Firestore version is different from stored version, reload.
        if (version !== frontEndVersion) {
          setFrontEndVersion(version);
          window.location.reload();
        }
      }, [version, frontEndVersion]);

  useEffect(() => {
    const versionDoc = doc(db, 'buildVersion','frontend');

    const unsubscribe = onSnapshot(versionDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const { version, force_refresh, update_message } = docSnapshot.data();
  
        setVersion(version);
      }

    });

    return () => unsubscribe();
  }, []);
};


