import { ProfileAPI } from "@/components/userprofile/profile";
import { app, auth, db, validateAuth } from "@/services/firebase";
import { PrivateProfile } from "@/types/privateProfile";
import Profile from "@/types/profile";
import { PublicClientApplication } from "@azure/msal-browser";
import {
	signInWithCustomToken,
	signOut as signOutFirebase,
} from "firebase/auth";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useOfficeContext } from "./office-client-context";

import { convertTimestampsToDates } from "@/hooks/useFirestoreCollection";
import { getIdBootstrapToken } from "@/utils/office-js-utils";
import { getMessaging, getToken } from "firebase/messaging";
import { useNavigate } from "react-router";
import { useAppVersionListener } from "@/hooks/useAppVersionListener";

//const provider = new OAuthProvider("microsoft.com");

const getMessagingToken = async () => {
	const messaging = getMessaging(app);
	return getToken(messaging, {
		vapidKey:
			"BNcepPj1Y7bj_Kb2bu0Le2r6ROf1b1bizG2h1NmTriX14iBdzXDoSCcxnlcxCylK6i4nb6FhVgnJFxIXz5Fqris",
	});
};

interface AuthContextState {
	msalLogin: () => void;
	clientId: string;
	signOut: () => Promise<void>;
	MSASignIn: () => void;
	errorStatus: string | undefined;
	inProgress: boolean;
	profile: Profile | undefined;
	privateProfile: PrivateProfile | undefined;
	isSignedIn: boolean;
	claims: { admin?: boolean; compliance?: boolean };
	updateProfile: (data: any) => Promise<void>;
	updatePrivateProfile: (profile: Partial<PrivateProfile>) => Promise<void>;
	getMsGraphToken: () => Promise<string | undefined>;
	profileAPI: {
		setTenantId: (tenantId: any) => void;
		subscribeToProfile: (email: any, handleProfileUpdate: any) => void;
		unsubscribeToProfile: (email: any, handleProfileUpdate: any) => void;
	};
}

interface AuthProviderProps {
	children: React.ReactNode;
	SignInPage: React.FC<{ handleSignIn: () => void }>;
	LoadingPage: React.FC;
}

const AuthContext = React.createContext<AuthContextState>(
	{} as AuthContextState
);

const defaultScopes = [
	"Files.ReadWrite",
	"Sites.ReadWrite.All",
	"Calendars.ReadWrite",
	"User.Read",
];

const clientId =
	window.location.host === "localhost:3000"
		? "aa831f3c-4577-4eee-9f97-3854c2ddc676"
		: window.location.host === "demo.softcap.no" ||
		  window.location.host === "softcapdemo.web.app"
		? "8e8796bc-fc42-459a-8805-34648a4533de" //"9bf8ba7e-9c3c-4c46-b121-0539abcf97f2"
		: "d090327f-dd7b-455f-a2eb-d65107cbc471";

const msalConfig = {
	auth: {
		clientId,
		authority:
			window.location.host === "localhost:3000"
				? "https://login.microsoftonline.com/c03784ed-8676-4e83-9508-a4865e3364cd"
				: "https://login.microsoftonline.com/common",
		redirectUri:
			window.location.protocol + "//" + window.location.host + "/",
	},
	cache: {
		cacheLocation: "localStorage",
	},
};

const msalInstance = new PublicClientApplication(msalConfig);

export function useAuth() {
	return useContext(AuthContext);
}

let dialog;
const processMessage = (
	args: { message: string; origin: string } | { error: number }
) => {
	console.log("Message received in dialog", args);
	return;
};

export const msalLogin = () => {
	msalInstance
		.loginRedirect({ scopes: defaultScopes })
		.then((result) => {
			console.log("Successful redirect login");
			console.dir(result);
		})
		.catch(async (error) => {
			console.error("MSAL signin failed:", error);
			await msalInstance.logoutRedirect();
		});
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
	children,
	SignInPage,
	LoadingPage,
}) => {
	const [profile, setProfile] = useState<Profile>();
	const [privateProfile, setPrivateProfile] = useState<any | undefined>();
	const [tenantId, setTenantId] = useState<string | undefined>();
	const [userId, setUserId] = useState<string | undefined>();
	const [messageTokenFetched, setMessageTokenFetched] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [isSignedIn, setIsSignedIn] = useState(false); // Wait for some user to have been resolved before rendering
	const [errorStatus, setErrorStatus] = useState<string | undefined>();
	const [inProgress, setInProgress] = useState(false);
	const officeContext = useOfficeContext();
	const profileAPI = ProfileAPI();
	const navigate = useNavigate();

	useAppVersionListener();

	///const {getBootstrapToken} = useOfficeContext();
	const [addinAccessToken, setAddinAccessToken] = useState<
		{ token: string; expireDateInSeconds: number } | undefined
	>();
	const [claims, setClaims] = useState<{
		admin?: boolean;
		compliance?: boolean;
	}>({});

	useEffect(() => {
		// Step 3: Initialize state from session storage
		if (officeContext && !officeContext.isAddin) {
			const storedData = sessionStorage.getItem("dmai.signInProgress");
			if (storedData) {
				setInProgress(Boolean(storedData));
			} else {
				setInProgress(false);
			}

			console.log("Initializing push notifications in app");
			const messaging = getMessaging(app);

			navigator.serviceWorker.addEventListener("message", (event) => {
				if (!event.data.action) {
					return;
				}

				switch (event.data.action) {
					case "redirect-from-notificationclick":
						navigate(event.data.path);
						break;
				}
			});
		}
	}, [officeContext]);

	/* useEffect(() => {
		// Step 4: Update session storage on state change
		console.log("Setting state for in progress", inProgress);
		sessionStorage.setItem("dmai.signInProgress", String(inProgress));
	}, [inProgress]); */

	// Update group memberships
	useEffect(() => {
		/* TODO: Move this to a function in the backend and protect 
		   the group memberships from being updated by the client 
		   
			  The security rules of the projects entity needs to enforce that
			only the backend can update the group memberships, since only allowed groups should be added?
		   */

		const updateGroupMemberships = async () => {
			console.log("Updating group memberships");
			const url = `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$select=displayName,id`;
			const token = await getMsGraphToken();
			// add token as bearer token and fetch url
			const response = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			// parse response
			const data = await response.json();

			const memberships = data.value.map(
				(group: { id: string }) => group.id
			);

			console.log("Flat memberships", memberships);

			// compare with current memberships
			// if different, update private profile
			if (
				privateProfile &&
				JSON.stringify(privateProfile.acl_groups) ===
					JSON.stringify(memberships)
			) {
				console.log("No change in memberships");
				return;
			}
			// update private profile
			updatePrivateProfile({
				...privateProfile,
				acl_groups: memberships,
			});
		};

		if (!profile) return;
		if (officeContext && !officeContext.isAddin) updateGroupMemberships();
	}, [profile, officeContext]);

	// Listen to profile changes
	useEffect(() => {
		if (!userId || !tenantId) return;
		const unsubscribe = onSnapshot(
			doc(db, `tenants/${tenantId}/profiles`, userId),
			(doc) => {
				if (doc.data()) {
					setProfile({
						...(doc.data() as Profile),
						uid: userId,
						tid: tenantId,
					});
				}
			}
		);
		return unsubscribe;
	}, [userId, tenantId]);

	// Listen to private profile
	useEffect(() => {
		if (!userId || !tenantId) return;
		const unsubscribe = onSnapshot(
			doc(db, `tenants/${tenantId}/privateProfiles`, userId),
			(doc) => {
				if (doc.data()) {
					let data = { ...doc.data() } as { [key: string]: any };
					convertTimestampsToDates(data);

					setPrivateProfile({
						...data,
					});
				}
			}
		);
		return unsubscribe;
	}, [userId, tenantId]);

	useEffect(() => {
		if (
			officeContext &&
			!officeContext.isAddin &&
			privateProfile &&
			!messageTokenFetched
		) {
			console.log(
				"Fetching message token, permission: ",
				Notification.permission
			);

			if (Notification.permission === "granted") {
				//Notification.requestPermission().then((permission) => {
				//	if (permission === "granted") {
				console.log("Notification permission granted.");
				getMessagingToken()
					.then((currentToken) => {
						if (currentToken) {
							console.log("Got Messaging token: ", currentToken);

							// Update message token dictionary in private profile
							//const tokens = privateProfile.messagingTokens || {};
							//tokens[currentToken] = serverTimestamp();
							//console.log("Updating messaging tokens: ", tokens);
							updatePrivateProfile({
								messagingTokens: {
									[currentToken]: new Date(),
								},
							});
						} else {
							// Show permission request UI
							console.log(
								"No Instance ID token available. Request permission to generate one."
							);
							// Show permission UI.
							//updateUIForPushPermissionRequired();
							//setTokenSentToServer(false);
						}
					})
					.catch((err) => {
						console.log(
							"An error occurred while retrieving token. ",
							err
						);
						// ...
					});
				//	}
				setMessageTokenFetched(true);
			} else {
				console.log(
					"Not fetching message token because user has not granted permission"
				);
				setMessageTokenFetched(true);
			}
		}
	}, [privateProfile, officeContext]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setErrorStatus(null);
				auth.currentUser
					.getIdTokenResult()
					.then(async (idTokenResult) => {
						const tid = idTokenResult.claims.ms_tid as string;
						const uid = idTokenResult.claims.ms_uid as string;
						const email = idTokenResult.claims.email as string;
						setClaims({
							admin: idTokenResult.claims.admin === true,
							compliance:
								idTokenResult.claims.compliance === true,
						});

						if (!(tid && uid)) {
							console.log("Missing claims");
							setErrorStatus("Missing claims");
							setShowLogin(true);
							setInProgress(false);
							return;
						}

						setTenantId(tid);
						setUserId(uid);
						//setEmail(email);
						profileAPI.setTenantId(tid);

						// Look for profile in session storage
						// If not found, fetch profile from firestore

						const profilePromise = getDoc(
							doc(db, `tenants/${tid}/profiles`, uid)
						);
						const privateProfilePromise = getDoc(
							doc(db, `tenants/${tid}/privateProfiles`, uid)
						);

						const [p, privp] = await Promise.all([
							profilePromise,
							privateProfilePromise,
						]);

						if (!p.exists() || !privp.exists()) {
							setErrorStatus("No profile - Please login again");
							setShowLogin(true);
							setInProgress(false);
							return;
						}
						setProfile(p.data() as Profile);
						setPrivateProfile(privp.data() as any);

						//setCurrentUser(user);
						setIsSignedIn(true);
						setShowLogin(false);
						setInProgress(false);
					});
			} else {
				console.log("Not logged in");
				setIsSignedIn(false);
				setProfile(undefined);
				setShowLogin(true);
				setInProgress(false);
			}
		});

		msalInstance
			.initialize()
			.then(() => {
				msalInstance
					.handleRedirectPromise()
					.then(async (tokenResponse) => {
						if (tokenResponse) {
							//dialog && dialog.close();

							try {
								const validatedToken = await validateAuth({
									token: tokenResponse.idToken,
								});
								console.log(
									"firebase token",
									validatedToken.data.token
								);

								signInWithCustomToken(
									auth,
									validatedToken.data.token
								).catch((error) => {
									console.error(
										"Firebase sign-in error:",
										error
									);
									setErrorStatus("MSAL-ERROR");
									// TODO: Handle error
								});
							} catch (error) {
								console.error(
									"Unable to validate token",
									error
								);
								setErrorStatus("ACCOUNT-ERROR");
								await msalInstance.logoutRedirect();
								// TODO - handle error and display error to user
							}
						}
					})
					.catch((error) => {
						// handle error, either in the library or coming back from the server
						console.error(
							"Unable to register redirect handler",
							error
						);
					});
			})
			.catch(async (error) => {
				console.error("Unable to initialize MSAL", error);
				setErrorStatus("MSAL-ERROR");
				//msalInstance.logoutRedirect();
			});

		return unsubscribe;
	}, []);

	async function MSASignIn() {
		setInProgress(true);
		setErrorStatus(null);
		if (officeContext.isAddin) {
			console.log(
				"MSA Signin from Office addin - getting bootstrap token"
			);
			// Get auth token from office application and use it to sign in to firebase

			getIdBootstrapToken()
				.then(async (token) => {
					console.log("Got a boostrap token", token);
					//console.log("Got boostrap token", token);
					//const decoded = jwt_decode(token as string);
					//console.log("The decoded token is", decoded);
					try {
						const validatedToken = await validateAuth({
							token: token,
						});
						console.log("firebase token", validatedToken);
						const uc = await signInWithCustomToken(
							auth,
							validatedToken.data.token
						);
						console.log("User signed in", uc);
					} catch (error) {
						console.error("MSAL sign-in error:", error);
						setErrorStatus("MSAL-ERROR");
					}
				})
				.catch((error) => {
					console.error("Unable to get bootstrap token", error);
					setErrorStatus("MSAL-ERROR");
				})
				.finally(() => {
					setInProgress(false);
				});
		} else {
			console.log("MSA Signin from web");
			msalLogin();
		}
	}

	const signOut = async () => {
		await signOutFirebase(auth);
		if (!officeContext.isAddin) {
			const signOutRes = await msalInstance.logoutRedirect(); // TODO - check if we are in an office addin and do the logout in the dialog window
		}
	};

	async function getMsGraphToken() {
		if (officeContext.isAddin) {
			const accessToken = await officeContext.getAccessToken();
			return accessToken;
		} else {
			try {
				const homeAccountId = userId + "." + tenantId;
				const account = msalInstance
					.getAllAccounts()
					.filter((a) => a.homeAccountId === homeAccountId)[0];

				//console.log("Account", account);
				//console.log("All account", msalInstance.getAllAccounts());

				let tokenResult;

				if (!account) {
					console.log(
						"No account found for user with home account id",
						homeAccountId,
						profile?.email,
						msalInstance.getAllAccounts()
					);
					// No account found, log out and try again
					tokenResult = await msalInstance.acquireTokenSilent({
						scopes: defaultScopes,
					});
				}

				tokenResult = await msalInstance.acquireTokenSilent({
					account: account,
					scopes: defaultScopes,
				});
				return tokenResult.accessToken;
			} catch (error) {
				console.log("Unable to fetch graph token silently:", error);
				console.log("Attempt interactive login");
				msalInstance
					.loginRedirect({ scopes: defaultScopes })
					.then((result) => {
						console.log("Successful MSAL signin");
						console.dir(result);
					})
					.catch((error) => {
						console.error("MSAL signin failed:", error);
					});
				await msalInstance.loginRedirect({ scopes: defaultScopes });
			}
		}
	}

	function updateProfile(profile: Partial<PrivateProfile>) {
		console.log("Updating profile", profile);
		return setDoc(
			doc(db, `tenants/${tenantId}/profiles`, userId),
			{
				...profile,
				updated: new Date(),
			},
			{ merge: true }
		).catch((error) => {
			console.log("profile update: Something went wrong:", error);
		});
	}

	function updatePrivateProfile(data: any, merge: boolean = true) {
		console.log("Updating private profile", data);
		return setDoc(
			doc(db, `tenants/${tenantId}/privateProfiles`, userId),
			{
				...data,
				updated: new Date(),
			},
			{ merge: true }
		).catch((error) => {
			console.log("profile update: Something went wrong:", error);
		});
	}

	const value = {
		clientId,
		claims,
		msalLogin,
		signOut,
		MSASignIn,
		errorStatus,
		inProgress,
		profile,
		privateProfile,
		isSignedIn,
		updateProfile,
		updatePrivateProfile,
		getMsGraphToken,
		profileAPI,
	};

	return (
		<AuthContext.Provider value={value}>
			{showLogin && <SignInPage handleSignIn={MSASignIn} />}
			{profile && privateProfile
				? children
				: !showLogin && <LoadingPage />}
		</AuthContext.Provider>
	);
};
