import * as React from "react";

export function useLocalStorageState<T>(key: string, initialValue: T) {
	// Read from local storage on the first render
	const [value, setValue] = React.useState<T>(() => {
		if (typeof window === "undefined") return initialValue;
		try {
			const stored = window.localStorage.getItem(key);
			return stored !== null ? (JSON.parse(stored) as T) : initialValue;
		} catch {
			return initialValue;
		}
	});

	const updateValue = React.useCallback(
		(newVal: T | ((prevVal: T) => T)) => {
			setValue((prev) => {
				const next =
					typeof newVal === "function"
						? (newVal as (prevVal: T) => T)(prev)
						: newVal;
				try {
					if (typeof window !== "undefined") {
						window.localStorage.setItem(key, JSON.stringify(next));
					}
				} catch {
					console.warn(`Error setting localStorage key "${key}":`);
				}
				return next;
			});
		},
		[key]
	);

	return [value, updateValue] as const;
}
